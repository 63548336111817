
import { Component, Vue } from 'vue-property-decorator';
import { loginServe } from '@/api/installServer';
import RequestPassword from '@/views/login/requestPassword.vue';

@Component({
  components: { RequestPassword },
})
export default class Registration extends Vue {
  iconChose = ''; // 被选中的图标
  isLoading = false; // 被选中的图标
  remPsdFlag = false; // 是否记住密码
  weixinShow = false; // 是否显示公众号二维码
  validatorCodeUrl = ''; // 验证码图片路径
  verificationCodeKey = ''; // 验证码存储的 key
  // 用户输入的账户密码验证码对象
  userInfo: object = {
    username: '', // 账号
    password: '', // 密码
    entName: '', // 企业名称
    // verificationCode: '', // 四位验证码
  };
  // 用户是否输入了账号密码验证码
  userInpFlag: object = {
    username: false,
    password: false,
    entName: false,
    verificationCode: false,
  };

  requestPasswordMark = false;

  needVerificationCodeKey = false; // 是否显示验证码输入框

  tipsMark = false; // 错误提示显示

  // type: any = '';

  /**
   * @desc Vue 创建数据生命周期，获取验证码
   * @param1 暂无
   * @returns 暂无
   */
  created() {}

  /**
   * @desc 当 input 标签被选中或失焦时更改 图标 的颜色。
   * @param1 传入 iconChose 给特定图标上色。
   * @returns 无
   */
  changeColor(iconChose: string, type?: string): void {
    type ? ((this.userInpFlag as any)[type] = false) : null;
    this.iconChose = iconChose;
  }

  /**
   * @desc 点击切换是否记住密码
   * @param1 无
   * @returns 无
   */
  isRememberPsd(): void {
    this.remPsdFlag = !this.remPsdFlag;
  }

  /**
   * @desc 当input 输入时会调用该函数，用于将红色图标和input变为蓝色。
   * @param1 用户的信息参数。
   * @returns 无
   */
  setInpColor(str: string): void {
    this.iconChose = str;
    (this.userInpFlag as any)[str] = false;
  }

  /**
   * 回车登录
   */
  entryLogin() {
    this.handleLogin();
  }

  /**
   * @desc 用户登录按钮点击事件，点击登录，先验证是否输入账号密码。
   * @param1   暂无
   * @returns 暂无
   */
  handleLogin(): void {
    this.isLoading = true;
    let n = true;
    let flag = true;
    // 判断是否填写所有内容
    for (const key in this.userInfo) {
      if ((this.userInfo as any)[key] == '') {
        flag = false;
        if (n) {
          (this.$refs[key] as any).focus();
          n = false;
        }
        (this.userInpFlag as any)[key] = true;
      }
    }
    if (flag) {
      // const obj: any = rsa({...this.userInfo, verificationCodeKey: this.verificationCodeKey});
      if (this.remPsdFlag === false) {
        this.$message.warning('请确认是否已阅读协议！');
        return;
      }
      // 发起登录请求
      // loginServe
      //   .userLogin(this.userInfo)
      //   .then((res: any) => {
      //     this.isLoading = false;
      //     console.log('Login Res: ', res);
      //     if (res) {
      //       switch (res.code) {
      //         case '200':
      //         case '101109':
      //           // console.log(res.data.other.dataMap);
      //           window.localStorage.setItem('userInfo', JSON.stringify(res.data.userInfo));
      //           window.localStorage.setItem('name', res.data.realName);
      //           window.localStorage.setItem('userId', res.data.userId);
      //           window.localStorage.setItem('token', res.data.token);
      //           this.$router.push('/index');
      //           this.$message.success('登录成功');
      //           break;
      //
      //         case '401':
      //           (this.$refs.username as any).focus(); // 聚焦
      //           (this.userInpFlag as any).username = true; // 红色提示
      //           this.tipsMark = true;
      //           break;
      //
      //         // case '10404':
      //         //   (this.userInfo as any).verificationCode = ''; // 清空内容
      //         //   (this.$refs.verificationCode as any).focus(); // 聚焦
      //         //   (this.userInpFlag as any).verificationCode = true; // 红色提示
      //         //   this.needVerificationCodeKey = true;
      //         //   this.tipsMark = true;
      //         //   break;
      //         default:
      //           (this.$refs.username as any).focus(); // 聚焦
      //           (this.userInpFlag as any).username = true; // 红色提示
      //           this.tipsMark = true;
      //           break;
      //       }
      //     } else {
      //       this.$message.error('登录失败，请重试！');
      //     }
      //     // this.getValidatorCode(); // 刷新验证码
      //   })
      //   .catch(() => {
      //     this.isLoading = false;
      //     // this.getValidatorCode(); // 刷新验证码
      //     this.$message.error('登录失败，请重试！');
      //   });
    } else {
      setTimeout(() => {
        this.isLoading = false;
      }, 300);
    }
  }

  /**
      @description: 注册
      @author: cxf
      @update: cxf 2023/3/24 16:54
   **/
  handleRegist() {
    this.$router.push('/businessRegistration');
  }

  /**
   * @desc 限制用户在 input 中输入 空格等非法字符。
   * @param1 传入 事件对象
   * @returns 无
   */
  noSpace(e: any) {
    e.target.value = e.target.value.replace(/[`~!#$%^&*()_\+=<>?:"{}|~！#￥%……&*（）={}|《》？：“”【】、；‘’，。、\s+]/g, '');
  }
}
