
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import SimpleVerify from 'vue-simple-verify';
import '../../../node_modules/vue-simple-verify/dist/vue-simple-verify.css';

@Component({
  components: { SimpleVerify },
})
export default class RequestPassword extends Vue {
  @Prop(Boolean) requestPasswordMark: boolean | undefined;
  findPwd = 0;
  iconChose = ''; // 被选中的图标
  passwordShow1 = false; //密码是否明文显示
  passwordShow2 = false;

  private async mounted() {
    const refStr = 'verify';
    const ref = this.$refs[refStr];
    (ref as any).width = 360;
  }

  private resetVerify() {
    const refStr = 'verify';
    const ref = this.$refs[refStr];
    (ref as any).reset();
  }

  // 验证成功之后触发的函数
  private verifySuccess() {
    console.log('success');
  }

  /**
   * @desc 点击马上登录
   * @param1
   * @returns 无
   */
  @Emit()
  nowLogin(requestPasswordMark: boolean) {
    this.$emit('update:requestPasswordMark', false);
    this.findPwd = 0;
    this.passwordShow1 = false;
    this.passwordShow2 = false;
    this.$forceUpdate();
  }

  /**
   * @desc 当用户名输入框中的值发生变化时
   * @param1 无
   * @returns 无
   */
  changeInputValue(): void {
    this.resetVerify();
  }

  /**
   * @desc 当 input 标签被选中或失焦时更改 图标 的颜色。
   * @param1 传入 iconChose 给特定图标上色。
   * @returns 无
   */
  changeColor(iconChose: string): void {
    this.iconChose = iconChose;
  }

  /**
   * @desc 当点击小眼睛时设置密码是否明文
   * @param1 传入 iconChose 给特定图标上色。
   * @returns 无
   */
  changeInputType(calssName: string): void {
    const input1 = document.getElementsByClassName('password1')[0];
    const input2 = document.getElementsByClassName('password2')[0];
    if (calssName === input1.getAttribute('class')) {
      this.passwordShow1 = !this.passwordShow1;
      if (this.passwordShow1) {
        input1.setAttribute('type', 'text');
      } else {
        input1.setAttribute('type', 'password');
      }
    } else if (calssName === input2.getAttribute('class')) {
      this.passwordShow2 = !this.passwordShow2;
      if (this.passwordShow2) {
        input2.setAttribute('type', 'text');
      } else {
        input2.setAttribute('type', 'password');
      }
    }
  }

  /**
   * @desc 点击下一步进行问题验证
   * @param1 无
   * @returns 无
   */
  nextOne() {
    this.findPwd = 1;
  }

  /**
   * @desc 点击下一步进行密码设置
   * @param1 无
   * @returns 无
   */
  nextTwo() {
    this.findPwd = 2;
  }

  /**
   * @desc 点击确认完成密码设置
   * @param1 暂无
   * @returns 暂无
   */
  confirmPwd() {}
}
